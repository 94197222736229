<!-- หน้านี้ไม่ได้ใช้แล้ว ถูกสร้างมาตอนทำ PWA แต่ใช้ LnwMeLayout ที่มี Popup แทน -->

<template>
    <LnwmeLayout :myOrderDrawer="false">
        <template #toolbar>
            <v-app-bar-nav-icon>
                <template v-if="isDesktop">
                    <v-icon @click.stop="myOrderDrawer = !myOrderDrawer">
                        {{ myOrderDrawer ? 'mdi-menu-open' : 'mdi-storefront-outline' }}
                    </v-icon>
                </template>
                <template v-else>
                    <v-icon @click.stop="myOrderDrawer = !myOrderDrawer">
                        mdi-menu
                    </v-icon>
                </template>
            </v-app-bar-nav-icon>
            <v-toolbar-title>
                {{ $t('Lnw.Me') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </template>
        <LoginComponent/>
    </LnwmeLayout>
</template>
<script>
import LoginComponent from "@/components/Authentication/LoginComponent.vue";
import LnwmeLayout from "@/views/layouts/LnwmeLayout.vue";
import {mapGetters} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "LoginPage",
    components: {LnwmeLayout, LoginComponent},
    mixins: [MixinsHelper],
    data: function (){
        return {
            myOrderDrawer: false
        }
    },
    methods: {
      redirectToApp(){
          this.$router.push({name: 'app.home'})
      }
    },
    computed: {
        ...mapGetters({
            userData: 'user/userData',
        })
    },
    mounted(){
        if(this.userData){
            this.redirectToApp();
        }
    },
    watch: {
        // ถ้าเจอว่า login แล้ว ให้ redirect ไปหน้า app
        userData(value){
            if(value){
                this.redirectToApp();
            }
        }
    },
};
</script>