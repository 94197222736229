<template>
<!--    Component นี้ใช้กับ app home page, shop order list -->
    <div class="py-2"
         id="login-wrapper">
        <div class="step"
             style="min-height: auto">
            <div class="lnwme-container px-6">
                <div class="stepContent buyerInfo">
                    <div class="center my-4 position-relative">
                        <div style="position: absolute; right: 0; transform: scale(0.75); transform-origin: right;">
                            <div class="flag-container"
                                 @click="toggleLangLogin()">
                                <div class="flag"
                                     :class="$i18n.locale + '-flag'"></div>
                                <span v-if="isDesktop"
                                      class="text-uppercase">{{ $i18n.locale }}</span>
                            </div>
                        </div>
                        <img height="25"
                             src="@/assets/lnw_new_logo.png"/>
                        <div class="caption mt-4 grey--text">
                            {{ $t('เพียงบัญชีเดียว ก็ใช้ได้ทุกเว็บเทพ') }}
                        </div>
                        <div class="caption grey--text"
                             style="margin-top: -0.125rem;">
                            <span style="font-size: 0.8em">{{ $t('สะดวก. รวดเร็ว. ปลอดภัย.') }}</span>
                        </div>
                    </div>
                    <template v-if="twoFactorToken">
                        <v-form @submit.prevent.stop="submit2faOtp(true)">
                            <div>
                                <div class="my-5">
                                    <div class="font-weight-bold">{{ $t('การยืนยัน 2FA') }}</div>
                                    <div class="text-body-2 my-2">{{ $t('รับรหัสยืนยันด้วย Authenticator App') }}</div>
                                </div>
                                <v-text-field v-model="twoFactorCode"
                                              :label="$t('OTP 6 หลัก')"
                                              name="otp"
                                              inputmode="numeric"
                                              autocomplete="one-time-code"
                                              :maxlength="6"
                                              class="mb-6"
                                              hide-details="auto"
                                              validate-on-blur
                                              :rules="[v => !!v || $t('กรุณากรอก OTP ด้วยนะคะ'), v => (v && (v.length === 6)) || this.$t('รูปแบบไม่ถูกต้อง')]"
                                              pattern="\d*"
                                              outlined>
                                    <template v-slot:message="{ message }">
                                        <span v-html="message"></span>
                                    </template>
                                </v-text-field>
                            </div>
                            <div>
                                <div>
                                    <v-btn x-large
                                           block
                                           depressed
                                           type="submit"
                                           :loading="isLoggingIn"
                                           color="primary">{{ $t('ยืนยัน') }}
                                    </v-btn>
                                </div>
                            </div>
                            <div class="minor mt-6">
                                {{ $t('ทำไมคุณถึงต้องยืนยัน ? เนื่องจากคุณได้เปิดระบบยืนยันตัวตนแบบ 2FA (Two-Factor Authentication) ไว้เพื่อความปลอดภัยของบัญชี กรุณากรอก OTP ที่ได้รับจาก Authenticator App ของคุณ') }}
                            </div>
                        </v-form>
                    </template>
                    <template v-else>
                        <v-form @submit.prevent.stop="login">
                            <div class="just_flex my-5">
                                <span class="d-inline-block">{{ $t('กรุณาเข้าสู่ระบบ') }}</span>
                                <v-btn text
                                       color="primary"
                                       :small="$i18n.locale == 'en'"
                                       :href="accountUrl('forgot_password')">{{ $t('ลืมรหัสผ่าน') }}
                                </v-btn>
                            </div>
                            <v-text-field v-model="username"
                                          ref="username"
                                          type="text"
                                          :label="$t('อีเมล') + ' ' + $t('หรือ') + ' ' + $t('เบอร์มือถือ')"
                                          required
                                          validate-on-blur
                                          v-disabled-icon-focus
                                          autocomplete="off"
                                          outlined></v-text-field>
                            <v-text-field v-model="password"
                                          ref="password"
                                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                          :type="showPassword ? 'text' : 'password'"
                                          :label="$t('รหัสผ่าน')"
                                          @click:append="showPassword = !showPassword"
                                          required
                                          validate-on-blur
                                          v-disabled-icon-focus
                                          autocomplete="off"
                                          outlined></v-text-field>
                            <div>
                                <v-btn x-large
                                       block
                                       depressed
                                       :loading="isLoggingIn"
                                       type="submit"
                                       color="primary">{{ $t('เข้าสู่ระบบ') }}
                                </v-btn>
                            </div>
                            <div class="social_login_container mt-8 flex_start">
                                <div class="social_login_text">
<!--                                    <template v-if="$i18n.locale == 'th'">{{ $t('หรือ') }}</template>-->
                                    {{ $t('เข้าสู่ระบบด้วย') }}
                                </div>
                                <div class="social_login">
                                    <div class="login_button">
                                        <div class="facebook_login">
                                            <button type="button"
                                                    class="button button-facebook"
                                                    @click="SocialLogin_via_Facebook(false)">
                                                <span class="logo"></span><span class="text">Facebook</span>
                                            </button>
                                        </div>
                                        <div class="line_login">
                                            <button type="button"
                                                    class="button button-line"
                                                    @click="SocialLogin_via_Line(false)">
                                                <span class="logo"></span><span class="text">LINE</span></button>
                                        </div>
                                        <div class="google_login">
                                            <button type="button"
                                                    class="button button-google"
                                                    @click="SocialLogin_via_Google(false)">
                                                <span class="logo"></span><span class="text">Google</span></button>
                                        </div>
                                        <div class="apple_login">
                                            <button type="button"
                                                    class="button button-apple"
                                                    @click="SocialLogin_via_Apple(false)">
                                                <span class="logo"></span><span class="text">Apple</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <v-divider class="m-4 p-0"></v-divider>
                            {{ $t('ยังไม่ได้สมัครสมาชิก') }}? <a :href="accountUrl('register')"
                                                                 target="_blank"
                                                                 color="primary">{{ $t('สมัครบัญชีใหม่') }}</a>
                        </v-form>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SiteUrl from "@/libraries/SiteUrl";
import MixinsSocialLoginMethods from "@/mixins/MixinsSocialLoginMethods.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper.vue";
import {mapActions} from "vuex";
import {setLocale} from "@/i18n";

export default {
    name: "LoginComponent",
    mixins: [MixinsSocialLoginMethods, MixinsHelper],
    data(){
        return {
            username: '',
            password: '',
            showPassword: false,
            isLoggingIn: false,
            twoFactorToken: "",
            twoFactorCode: "",
            // twoFactorCodeShow: false
        }
    },
    methods: {

        ...mapActions({
            actionSinglePurposeAuthenticateByUsernamePassword: "user/singlePurposeAuthenticateByUsernamePassword",
            actionVerifyOtp: 'user/verifyOtp',
            actionFetchOrders: "myOrders/fetchOrders",
        }),
        afterAuthenticate(){
            this.isLoggingIn = false;
            if(this.$route.params?.web_name) {
                this.actionFetchOrders();
            }
        },
        async login(){
            this.isLoggingIn = true;

            const success = await this.actionSinglePurposeAuthenticateByUsernamePassword({
                username: this.username,
                password: this.password
            });
            if(success === true){
                await this.afterAuthenticate();
            }else if(success && typeof success === "object" && success.hasOwnProperty('twoFactorToken')){
                this.twoFactorToken = success.twoFactorToken;
            }
            this.isLoggingIn = false;
        },
        async submit2faOtp(justLogin = false){
            this.isLoggingIn = true;
            const success = await this.actionVerifyOtp({
                twoFactorToken: this.twoFactorToken,
                twoFactorCode: this.twoFactorCode,
                justLogin: justLogin
            })
            if(success){
                await this.afterAuthenticate()
            }else{
                this.twoFactorCode = null;
            }
            this.isLoggingIn = false;
        },
        accountUrl(uri){
            return SiteUrl.account(uri);
        },
        toggleLangLogin(){
            this.overlay = true;
            if(this.$i18n.locale == 'th'){
                setLocale('en');
            }else{
                setLocale('th');
            }
            this.overlay = false;
            // window.location.reload();
        },
    },
};
</script>
<style scoped></style>